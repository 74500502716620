import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import React, {useEffect} from "react";
import CandidateLanding from "./CandidateLanding";
import FooterPage from "components/Footer";
import GermanLanguagePage from "../home/GermanLanguage";
import SEO from "components/seo/SEO";
import ServicePortfolioPage from "./ServicePortfolio";
import pathway from "images/migration.svg";
import { portfolioServiceData } from "data/dummyData";
import prototypeIllustrationImageSrc from "images/work.svg";


function CandidatesPage() {

  useEffect(() => {
    document.title = "Relocation Agency in Germany  | Weg2De Relocation Services";
  }, []);
  
  return (
    <AnimationRevealPage>
       <SEO
        title="Relocation Agency in Germany | Weg2De Relocation Services"
        description="Planning to move to Germany or the DACH region for work, study, or vocational training? Or do you already live in Germany and need help with Kindergeld, residence permits, or Elterngeld applications? Weg2De is here for you! Our expert team specializes in relocation services, visa support, and seamless transitions for professionals, students, and families. Whether you're pursuing an apprenticeship, furthering your education, or relocating for a career, we provide personalized guidance every step of the way. Start your journey with confidence—contact Weg2De today!"
        name="Relocation to Germany Made Easy | Weg2De Relocation Services - Candidates"
        keyword="relocation agency in germany, relocation agency in Wuppertal, relocation to germany, relocate to germany, weg2de, Apply for German Visa, Residence Permit, Kindergeld, Elterngeld at Weg2De"
        type="website"
      />
      <CandidateLanding imageSrc={pathway} showDecoratorBlob={true} />
      <ServicePortfolioPage
        heading="Our Services"
        data={portfolioServiceData}
      />
<GermanLanguagePage
        subheading="Book a discovery Call"
        heading="Want to relocate to Germany?"
        description="Are you looking to move to Germany and need help choosing the right pathway for you? Book our discovery call to get an insight into available opportunities in Germany"
        primaryButtonText="Discovery Call"
        primaryButtonUrl="https://termin.weg2de.com/#/en-de"
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={true}
      />
      <FooterPage />
    </AnimationRevealPage>
  );
}

export default CandidatesPage;
