import { HighlightedText, MainHeading } from "components/misc/Headings";
//import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";
//import HompageImg from "images/homep.jpg"

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section` md:pl-5 relative flex-1`;
const Pill = tw.span`font-serif md:tracking-widest px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
//const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section`mt-10  flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;

//const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
//const Icon = tw.div`hidden md:block mr-2 `;
//const ListWrapper = tw.ul`ml-3 md:ml-0 list-disc mt-2 md:list-none text-primary-500`;
const Container = tw.section`md:mt-10 max-w-screen-xl mx-auto`;

//const Image = tw.img` cursor-pointer bg-gray-100  w-80 h-80 rounded-full object-cover`;
const AdmissionRequirements = ({ 
  heading, 
  highlight }) => {
  return (
    <Container>
      <MainHeading>
        {heading}
        <HighlightedText>{highlight} </HighlightedText>
      </MainHeading>
      <TwoColumn>
        <LeftColumn>
                
                         
                           <TextItem>
                             <Pill>Studienkolleg</Pill>
                           </TextItem>
                      
                         <SectionDescription>
                           For students whose qualifications are not directly recognized in
                           Germany, the <b>Studienkolleg</b> is a one-year foundation
                           course that prepares you for university entry. <br/><br/>
                           
                           <li>High School Diploma</li>
                           
                           <li>Proof of <b>German language proficiency</b> B1</li> You must pass the{" "}
                           <b>Feststellungsprüfung</b> (final assessment exam) at the end
                           of the Studienkolleg to gain eligibility for a Bachelor’s
                           degree.
                       
                         
                         </SectionDescription>
                      

                           <TextItem>
                             <Pill>Bachelor’s Programs</Pill>
                           </TextItem>
          
                         <SectionDescription>
                           To apply for a Bachelor’s program in Germany, you will typically
                           need:
                           <br />
                            <li><b>secondary school diploma:</b> (e.g., A-levels, high school diploma, or International
                              Baccalaureate)</li> 
                              <li> <b>English language proficiency</b> (e.g., IELTS or TOEFL
                                scores) or </li> 
                          
                             <li><b>German language proficiency:</b> For programs
                             taught in German.</li> 
                             
                           
                          
                           
                         </SectionDescription>
                       
                     

        </LeftColumn>
        <RightColumn>
        <Pill>Master’s Programs</Pill>
        <SectionDescription>Admission to a Master’s program usually requires:</SectionDescription>
                      <SectionDescription>
                          <li><b>Bachelor’s degree</b> (or equivalent) in a related field.</li>                        
                        <li><b>Strong academic record</b> (transcripts, GPA).</li>  
                        <li><b>Language proficiency</b> - (IELTS, TOEFL,
                          or equivalent for English-taught programs) or B1 German</li> 
                        
                  <li>Depending on the course, you may also need to submit a
                          <b> motivation letter, CV</b>, and{" "}
                          <b>letters of recommendation</b>.</li>
                       
                          </SectionDescription>

                      <SectionDescription>
                        <Pill>PhD or Research</Pill>
                        <SectionDescription>PhD candidates must meet the following criteria:</SectionDescription>
            
                  <li><b>Master’s degree</b> or an equivalent qualification in a
                  related subject.</li> 
                
                
                  <li><b>research proposal</b> outlining your intended PhD
                  project.</li> 
               
               
                  {" "}
                  <li><b>Language proficiency</b> Either in German or English,
                  depending on the program.</li> 
               
              </SectionDescription>
       
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default AdmissionRequirements;
