import { HighlightedText, MainHeading } from "components/misc/Headings";

import IMG from "images/india.png";
import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { Link } from "react-router-dom";



const Container = styled.section`
  ${tw`mt-32`}
`;

const TwoColumn = tw.section`flex flex-col lg:flex-row  max-w-screen-xl mx-auto`;
const LeftColumn = tw.section`relative  md:w-8/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;
const RightColumn = tw.section`relative md:w-4/12 my-5 lg:mt-0 flex-1 flex flex-col justify-center lg:self-center`;
const Notification = tw.div` mt-4 mb-10 pl-3 py-1 text-gray-500 border-l-4 text-left border-primary-500 font-medium text-sm`;
const Wrapper = tw.section`max-w-screen-xl mx-auto`;
const Paragraph = tw.p`my-5 font-serif lg:my-8 text-lg xl:text-xl text-gray-500`;
//const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;
const ButtonsContainer = styled.div`
  ${tw`flex lg:justify-start justify-center md:items-center my-10 flex-wrap`}
`;

const FirstButton = styled(Link)`
  ${tw`font-serif px-10 lg:tracking-wide sm:tracking-tighter text-sm uppercase md:px-10  bg-secondary-500 text-white font-normal my-4 sm:my-2 rounded-full py-2 md:py-6 text-center sm:leading-none focus:outline-none hover:bg-white hover:border hover:border-secondary-500 hover:text-gray-500 transition duration-300`}
`;

const SecondButton = styled(Link)`
  ${tw`font-serif px-10 lg:tracking-wide sm:tracking-tighter text-sm uppercase md:px-10  bg-white text-gray-500 border border-gray-500 font-normal md:mr-2 my-4 sm:my-2 rounded-full py-2 md:py-6 text-center  sm:leading-none focus:outline-secondary-500 hover:bg-secondary-500 hover:border-0 hover:text-white transition duration-300`}
`;

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageShadow && tw`shadow`,
]);

function CompanyLanding() {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
        <Paragraph>
        
            </Paragraph>
          <MainHeading>
          
         <br /><HighlightedText>RELOCATION</HighlightedText> services für <br />Privatpersonen, Familien und {" "}
            <HighlightedText>Unternehmen</HighlightedText>
          </MainHeading>
          <Wrapper>
            <Notification>Weg2De Relocation Agentur bietet die besten Relocation Lösungen, die auf Ihre spezifischen Bedürfnisse zugeschnitten sind. Egal ob es um Mitarbeiter Relocation, Anerkennung, Aufenthaltsgenehmigung oder Visum geht. Wir sind Ihr kompetenter Partner. Kontaktieren Sie uns heute!</Notification>
          </Wrapper>
          <ButtonsContainer>
              <SecondButton to="#services">
              Services Entdecken
              </SecondButton>
              <FirstButton to="https://termin.weg2de.com/#/relocation-for-companies">
              Termin Vereinbaren
              </FirstButton>
            </ButtonsContainer>
        </LeftColumn>
        <RightColumn>
        <Image 
        src={IMG}
        alt="Wuppertal"
        tw="min-w-0 w-full max-w-lg  h-full"
       />


        </RightColumn>
      </TwoColumn>
    </Container>
  );
}

export default CompanyLanding;
