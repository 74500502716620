import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";
const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 md:mt-10  relative flex-1`;
const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section`md:mt-5 flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
//const SubTitle = tw(Subheading)` tracking-widest`;

const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`ml-3 md:ml-0 list-disc mt-2 md:list-none text-primary-500`;
const Pill = tw.span`font-serif md:tracking-widest px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const Container = tw.section`max-w-screen-xl mx-auto`;

const CourseObjectivePage = () => {
  return (
    <Container>
      <TwoColumn>
        <LeftColumn>
          <Pill>Admission Requirements</Pill>
          <SectionDescription>
            Here’s what you need to know about the general admission
            requirements:
          </SectionDescription>
          <ListWrapper>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Studienkolleg</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                For students whose qualifications are not directly recognized in
                Germany, the <b>Studienkolleg</b> is a one-year foundation
                course that prepares you for university entry. You must pass the{" "}
                <b>Feststellungsprüfung</b> (final assessment exam) at the end
                of the Studienkolleg to gain eligibility for a Bachelor’s
                degree.
                <br />
                <br />
                
                  Proof of <b>German language proficiency</b> for programs
                  taught in German (often B1 or B2 level).
              
              </SectionDescription>
            </ListItem>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Bachelor’s Programs</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                To apply for a Bachelor’s program in Germany, you will typically
                need:
                <br />
                 <li><b>secondary school diploma:</b></li> A diploma equivalent to the German
                  Abitur (e.g., A-levels, high school diploma, or International
                  Baccalaureate)
               
                  <li><b>German language proficiency:</b></li> For programs
                  taught in German (often B2 or C1 level).
              
                
                  {" "}
                  For programs taught in English:{" "}
                  <li> <b>English language proficiency</b></li> (e.g., IELTS or TOEFL
                  scores) is required.
                
                
                  In some cases, you may need to complete a Studienkolleg, a
                  one-year preparatory course if your school-leaving certificate
                  is not recognized as equivalent.
                
              </SectionDescription>
            </ListItem>
          </ListWrapper>
        </LeftColumn>
        <RightColumn>
          <ListWrapper>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Master’s Programs</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Admission to a Master’s program usually requires:
                <br />
                <br />
              
                  <li><b>Bachelor’s degree</b></li> (or equivalent) in a related field.
               
               
                <li><b>Strong academic record</b></li>  (transcripts, GPA).
               
                
                <li><b>Language proficiency</b></li> German for programs taught in
                  German or English for English-taught programs (IELTS, TOEFL,
                  or equivalent).
               
                
                  For programs taught in English,{" "}
                  <li><b>English language proficiency</b></li> (e.g., IELTS or TOEFL
                  scores) is required.
              
                
                  <br/>Depending on the course, you may also need to submit a
                  <b>motivation letter, CV</b>, and{" "}
                  <b>letters of recommendation</b>.
               
              </SectionDescription>
            </ListItem>

            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>PhD Programs</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                PhD candidates must meet the following criteria:
                <br />
                <br />
                
                  <li><b>Master’s degree</b></li> or an equivalent qualification in a
                  related subject.
                
                
                  <li><b>research proposal</b></li> outlining your intended PhD
                  project.
               
               
                  {" "}
                  <li><b>Language proficiency</b></li> Either in German or English,
                  depending on the program.
               
              </SectionDescription>
            </ListItem>
          </ListWrapper>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default CourseObjectivePage;
