import AnimationRevealPage from "helpers/AnimationRevealPage";
import React, {useEffect} from "react";
import FooterPage from "components/Footer";
import GermanLanguagePage from "pages/home/GermanLanguage";
import LangIMG from "images/uni-admission.svg";
import OverviewSection from "../university-admissions/OverviewSection";
import FaqPage from "components/FAQ";
import { UniFaqs, UniTestimonials } from "data/dummyData";
import FAQIMG from "images/ishaq.webp"
// import RegistrationForm from "../online-group/RegistrationForm";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import SEO from "components/seo/SEO";


//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";
import LandingPage from "./LandingPage";
import AdmissionRequirements from "./AdmissionRequirements";
import UniTestimonialPage from "./UniTestimonial";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw.section` mt-12  relative`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;


function UniversityAdmission() {

  useEffect(() => {
    document.title = "University Admission | Study in Germany - Free Tuition Universities in Germany - Weg2De";
  }, []);
  return (
    <AnimationRevealPage>
      <LandingPage/>
       <SEO
        title="Study for free in Germany | University Admissions | Free Tuition Universities in Germany  - Weg2De"
        description="Study for free in Germany - Looking for Study in Germany expert? | Study in Germany - Free Tuition Universities in Germany"
        name="University Admissions "
        type=""
      />

      <Container>
        <OverviewSection heading="Why you should Study in "highlight=' Germany' />
        <UniTestimonialPage
      subheading="What our customers say"
      heading="SUCCESS STORIES"
      testimonials={UniTestimonials}/>
        <AdmissionRequirements heading="Admission Requirements in "highlight=' Germany' />
        <FaqPage
        subheading="FAQS"
        heading="Do you have Questions?"
        imageSrc={FAQIMG}
        faqs={UniFaqs}
      />
        <GermanLanguagePage
          subheading="Study in Germany"
          heading="Want to study in Germany?"
          description="Ready to take the next step toward studying in Germany? 
          Whether you need help finding the right university, applying, 
          or securing your visa, we're here to assist you every step of the way.
          Book a discovery call now and let’s get started on making your academic dreams in Germany a reality!"
          primaryButtonText="Book a discovery call"
          imageSrc={LangIMG}
          showDecoratorBlob={true}
          textOnLeft={false}
          primaryButtonUrl="https://termin.weg2de.com/#/en-de"
        />
        <FooterPage />
        <DecoratorBlob1 />
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
}

export default UniversityAdmission
