import {
HighlightedText,
MainHeading,
} from "components/misc/Headings.js";

import { PrimaryButtonLink } from "components/misc/Links";
import React from "react";
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
import styled from "styled-components";
import tw from "twin.macro";


const Container = tw.div`mt-32 relative max-w-screen-xl mx-auto`;
const TwoColumn = tw.div` mt-5 flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-6/12`,
  props.textOnLeft
    ? tw` md:order-first`
    : tw`  md:order-last`,
]);

const Image = styled.img((props) => [
  props.imageRounded && tw`rounded`,
  props.imageBorder && tw``,
  props.imageShadow && tw``,
]);

const DecoratorBlob = tw(
  SvgDotPattern
)`w-20 h-20 absolute right-0 bottom-0 transform translate-x-1/2 translate-y-1/2 fill-current text-secondary-500 -z-10`;

const TextContent = tw.div`text-center md:text-left`;
const Description = tw.p`text-justify  text-sm md:text-base lg:text-lg font-light leading-loose text-gray-500`;

const Wrapper = tw.div`mt-10 text-center lg:text-left`;

function CandidateLanding({
  description = "Are you considering moving to Germany or another country in the DACH region for work, study, or vocational training or Do you already live in Germany and need help with application for Kindergeld, Residence Permit, Elterngeld etc. you're in the right place. At Weg2De, we specialize in helping individuals like you navigate the complexities of relocating, from finding the right opportunities to securing the necessary visas and making the transition as smooth as possible. Whether you're a student aiming to further your education, a professional seeking career advancement, or someone interested in an apprenticeship, our expert team is here to guide you every step of the way. Speak to us today to start your journey with confidence.",
  primaryButtonText = "Book a discovery call",
  primaryButtonUrl = "https://termin.weg2de.com/#/en-de",
  imageSrc,
  buttonRounded = true,
  imageRounded = true,
  imageBorder = false,
  imageShadow = true,
  showDecoratorBlob = true,
  textOnLeft = true,
}) {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.

  /*
   * Change the features variable as you like, add or delete objects
   * `icon` must be a React SVG component. See how BriefcaseIcon is imported above. For a full list of available icons, see Feather Icons.
   */

  return (
    <Container>
         <MainHeading>Your Relocation <HighlightedText>Agency</HighlightedText> in <HighlightedText>Germany</HighlightedText> <br/>&amp; DACH region</MainHeading>
      <TwoColumn>
        <ImageColumn>
          <Image
            src={imageSrc}
            imageBorder={imageBorder}
            imageShadow={imageShadow}
            imageRounded={imageRounded}
          />
          {showDecoratorBlob && <DecoratorBlob />}
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            <Description>{description}</Description>
            <Wrapper>
            <PrimaryButtonLink
              buttonRounded={buttonRounded}
              to={primaryButtonUrl}
            >
              {primaryButtonText}
            </PrimaryButtonLink>
            </Wrapper>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
}

export default CandidateLanding;
