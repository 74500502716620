import AnimationRevealPage from "helpers/AnimationRevealPage";
//import ContactForm from "./ContactForm";
import FooterPage from "components/Footer";
import React, {useEffect} from "react";
import SEO from "components/seo/SEO";
import ContactUsForm from "./ContactUsForm";
//eslint-disable-next-line


function ContactUsPage() {

  useEffect(() => {
    document.title = "Contact Us | Weg2De elocation Services";
  }, []);
  return (
    <AnimationRevealPage>
      <SEO
        title="Contact Us - | Weg2De Relocation Services"
        description="Planning to move to Germany or the DACH region for work, study, or vocational training? Or do you already live in Germany and need help with Kindergeld, residence permits, or Elterngeld applications? Weg2De is here for you! Our expert team specializes in relocation services, visa support, and seamless transitions for professionals, students, and families. Whether you're pursuing an apprenticeship, furthering your education, or relocating for a career, we provide personalized guidance every step of the way. Start your journey with confidence—contact Weg2De today!"
        name="Weg2De - Contact Us"
        type=""
      />
      <ContactUsForm />
     {/*<ContactForm />*/}
     <FooterPage /> 
    </AnimationRevealPage>
  );
}

export default ContactUsPage;
