import AnimationRevealPage from "helpers/AnimationRevealPage";
import React, {useEffect} from "react";
import CourseObjectivePage from "./CourseObjectivePage";
import FooterPage from "components/Footer";
import GermanLanguagePage from "pages/home/GermanLanguage";
import LangIMG from "images/volunteer.svg";
import OverviewSection from "./OverviewSection";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";
import SEO from "components/seo/SEO";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw.section` mt-32  relative`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;


function VolunteeringPage() {
  useEffect(() => {
    document.title = "Volunteering In Germany | FSJ & BFD in Germany | Volunteer Work in Germany - Weg2De";
  }, []);
  return (
    <AnimationRevealPage>
       <SEO
        title="Volunteering In Germany | FSJ & BFD in Germany | Volunteer Work in Germany - Weg2De"
        description="Volunteering In Germany | FSJ & BFD in Germany | Volunteer Work in Germany - Weg2De"
        name="Volunteering In Germany | FSJ & BFD in Germany | Volunteer Work in Germany - Weg2De "
        type=""
      />
      <Container>
        <OverviewSection heading="Volunteering In " highlight=' Germany' />
        <CourseObjectivePage />
        <GermanLanguagePage
          subheading="Talk to us"
          heading="Want to speak to a consultant?"
          description="Ready to start your journey with FSJ or BFD in Germany? 
          We're here to help! Whether you have questions about the programs,
           need assistance with your application, or want to learn more about our services, 
          don’t hesitate to reach out. G
          et in touch with us today and let us guide you through every step towards an enriching experience in Germany!"
          primaryButtonText="Contact us"
          imageSrc={LangIMG}
          showDecoratorBlob={true}
          textOnLeft={false}
          primaryButtonUrl="/contact-us"
        />
     
        <FooterPage />
        <DecoratorBlob1 />
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
}

export default VolunteeringPage
