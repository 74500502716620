//import React from "react";
//import styled from "styled-components";
//import tw from "twin.macro";
import "react-toastify/dist/ReactToastify.css";

import {
  HighlightedText,
  MainHeading,
  //Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import React, { } from "react";
import { ToastContainer,  } from "react-toastify";

//import LocationIMG from "images/location-icon.svg";
import MailIMG from "images/mail-icon.svg";
import { SectionDescription } from "components/misc/Typography";
import styled from "styled-components";
import tw from "twin.macro";

const Container = styled.section`
  ${tw`mt-20 max-w-screen-xl mx-auto`}
`;


const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`shadow-lg p-5 md:p-10   w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const TextColumn = styled(Column)((props) => [
  tw`md:w-7/12 mt-16 md:mt-0 md:pr-24`,
]);
const RightContainer = tw.div`pt-24 px-10 md:px-20 shadow-lg rounded md:rounded-r md:w-5/12 bg-secondary-500`;
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

//const Subheading = tw(SubheadingBase)`text-center md:text-left`;

const SubTitle = tw.h3`uppercase text-gray-100 text-sm md:text-lg font-serif tracking-widest`;
const Title = tw.h2`text-gray-100 text-lg md:text-xl my-10 font-serif`;
const Text = tw.p`text-gray-100 text-lg md:text-xl font-serif -mb-5`;
const SmallText = tw.p`text-gray-300 mt-0 font-thin text-sm md:text-lg tracking-wide mt-10 font-serif`;
const Wrapper = tw.div`flex mb-8`;
const IconWrapper = tw.div`mr-5 bg-white w-8 h-8 rounded-full flex items-center justify-center`;


const ContactUsForm = () => {
  return (
    <Container id='register'>
      <TwoColumn>
        <TextColumn>
          <TextContent>
            <MainHeading>
              Are you interested in an <HighlightedText>Individual Course? </HighlightedText>
            </MainHeading>
            <SectionDescription>
              Sign up now and we will get in touch with you
              <br />
              Register via the form below or send an email to
              <HighlightedText> Akademie@weg2de.com</HighlightedText>
            </SectionDescription>
            <iframe
        title="Course Registration Form"
        aria-label="Course Registration Form"
        width="100%"
        height="900px"
        src="https://forms.weg2de.com/weg2de/form/CourseEnrollmentFormIndividual/formperma/57FbhaA7terLyM7s5OGH8SbyTVgjzeNgeTslsCBxdEI"
        frameborder="0"
        allowfullscreen=""
      >
        {" "}
      </iframe>
            </TextContent>
        </TextColumn>

        <RightContainer>
          <SubTitle>CONTACT DETAILS</SubTitle>
          <Title>General Information</Title>
          <Wrapper>
            <IconWrapper>
              <img
                src={MailIMG}
                style={{ width: "20px" }}
                alt="location icon"
              />
            </IconWrapper>
            <div>
              <Text>Email Address</Text>
              <SmallText>info@weg2de.com</SmallText>
            </div>
          </Wrapper>

          <Title>Weg2De Akademie</Title>
          <Wrapper>
            <IconWrapper>
              <img
                src={MailIMG}
                style={{ width: "20px" }}
                alt="location icon"
              />
            </IconWrapper>
            <div>
              <Text>Email Address</Text>
              <SmallText>akademie@weg2de.com</SmallText>
              <br/>
              <Text>WhatsApp</Text>
              <SmallText>+4917666572214</SmallText>
            </div>
          </Wrapper>
         {/* <Wrapper>
            <IconWrapper>
              <img
                src={LocationIMG}
                style={{ width: "20px" }}
                alt="location icon"
              />
            </IconWrapper>
            <div>
              <Text>Location</Text>
              <SmallText>Germany</SmallText>
              <SmallText>Germany</SmallText>
              <SmallText>Turkey</SmallText>
            </div>
          </Wrapper>*/}
        </RightContainer>
      </TwoColumn>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        theme="dark"
      />
    </Container>
   
  );
};

export default ContactUsForm;


