import React from "react";
import './CloudImage.css'; // Import the CSS file for styling

const CloudImage = ({ src, alt }) => {
  return (
    <div className="cloud-container">
      <img src={src} alt={alt} className="cloud-image" />
    </div>
  );
};

export default CloudImage;
