import React from "react";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

import { SectionHeading } from "components/misc/Headings.js";
//eslint-disable-next-line
import { ReactComponent as SvgDotPattern } from "images/dot-pattern.svg";
//eslint-disable-next-line


const Container = tw.section`relative mt-20 max-w-screen-xl mx-auto`;


const SectionTitle = tw(SectionHeading)`font-serif text-center mt-10 mb-3`;

const AppointmentBooking = ({ data, heading }) => {
  return (
    <Container>
      <SectionTitle id='contact'>{heading}</SectionTitle>
      <iframe
        title="Kontakt Aufnehmen"
        aria-label="Kontakt aufnehmen"
        width="100%"
        height="1050px"
        src="https://forms.weg2de.com/weg2de/form/ClientDetails/formperma/EFARoKrI9Kc670-Iqd5qpUZ1oMLzGZjez5WPm1nnkKE?zf_lang=de"
        frameborder="0"
        allowfullscreen=""
      >
        {" "}
      </iframe>
    </Container>
  );
};

export default AppointmentBooking;


