import {
  HighlightedText,
  MainHeading,
  Subheading,
} from "components/misc/Headings";

import { BsBarChartFill } from "react-icons/bs";
import { FaClock } from "react-icons/fa6";
import { LuMonitor } from "react-icons/lu";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section`md:pr-5 relative flex-1`;
const RightColumn = tw.section`md:pl-5 relative flex-1`;
const Pill = tw.span`font-serif tracking-widest px-2 mt-16 py-2 rounded-full text-white text-left bg-secondary-500 inline w-5/6 md:w-1/3`;
const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section`mt-5  flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;
const SubTitle = tw(Subheading)`text-lg tracking-widest`;
const Info = tw.span`text-left block font-serif`;
const Container = tw.section`md:mt-10 max-w-screen-xl mx-auto`;

const Overview = () => {
  return (
    <Container>
      <MainHeading>
        German <HighlightedText>Online</HighlightedText> Group{" "}
        <HighlightedText>Course</HighlightedText>
      </MainHeading>
      <TwoColumn>
        <LeftColumn>
          {/*<Pill>96 lessons of 45min</Pill>*/}
          <br/>
          <Info>
            <HighlightedText>
              <strong>Duration:</strong>
            </HighlightedText>{" "}
            8 - 12 weeks{" "}
          </Info>
          <SectionDescription>
            Immerse yourself in the German language with our interactive and
            dynamic online group course, designed for adult learners at all
            proficiency levels from A1 (beginner) to B2 (upper-intermediate).
            Whether you’re starting from scratch or looking to enhance your
            existing skills, our course provides a comprehensive learning
            experience tailored to your needs. Learn German Online at Weg2De Akademie
          </SectionDescription>
          <SectionDescription>
            <strong>Course Structure:</strong> Our German Online Group Course is
            divided into four progressive levels: A1, A2, B1, and B2. Each level
            consists of modules that cover essential aspects of the language,
            including speaking, listening, reading, and writing. Classes are
            conducted in small groups to ensure personalized attention and ample
            opportunity for interaction and practice.
          </SectionDescription>
        </LeftColumn>
        <RightColumn>
          <IconWrapper>
            <BsBarChartFill size={25} style={{ marginRight: "5px" }} />
            <SubTitle>LEVEL - A1,A2,B1,B2 (CEFR)</SubTitle>
          </IconWrapper>
          <SectionDescription>
            We offer Group Courses for all levels. You will be sorted into
            classes after taking a placement test
          </SectionDescription>
          <IconWrapper>
            <LuMonitor size={25} style={{ marginRight: "5px" }} />
            <SubTitle>LIVE ONLINE CLASSES</SubTitle>
          </IconWrapper>
          <SectionDescription>
            Participate in live sessions led by experienced native-speaking
            instructors. Engage in real-time conversations, group activities,
            and interactive exercises. Classes take place online via our
            Learning Management System
          </SectionDescription>
          <IconWrapper>
            <FaClock size={25} style={{ marginRight: "5px" }} />
            <SubTitle>NEXT SESSION STARTS</SubTitle>
          </IconWrapper>
       
          <SectionDescription>
           
            {" "}
            <Pill>3rd March 2025</Pill>
          </SectionDescription>
          <SectionDescription> 
            <SubTitle>MAXIMUM STUDENTS PER GROUP </SubTitle>
            <Pill>
              <strong>10</strong>
            </Pill>
          </SectionDescription>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default Overview;
