import { WhatWeDoData, homepageFaqs } from "data/dummyData";

import AboutPage from "./About";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import CompanyServicesPage from "./ServicePortfolio";
import FAQIMG from "images/ishaq.webp";
import FaqPage from "components/FAQ";
import FooterPage from "components/Footer";
import GermanLanguagePage from "./GermanLanguage";
import LandingPage from "./LandingPage";
import LearnGerman from "images/learn-german.svg";
import React from "react";
import SEO from "components/seo/SEO";

// title, description, name, type

const HomePage = () => {
  return (
    <AnimationRevealPage>
      <SEO
        title="Relocation to Germany Made Easy | Weg2De Relocation Services"
        description="Planning to move to Germany or the DACH region for work, study, or vocational training? Or do you already live in Germany and need help with Kindergeld, residence permits, or Elterngeld applications? Weg2De is here for you! Our expert team specializes in relocation services, visa support, and seamless transitions for professionals, students, and families. Whether you're pursuing an apprenticeship, furthering your education, or relocating for a career, we provide personalized guidance every step of the way. Start your journey with confidence—contact Weg2De today!"
        name="Weg2De Homepage"
        type=""
      />
      <LandingPage />
      <AboutPage />
      <CompanyServicesPage heading="What We Do" data={WhatWeDoData} />
      <GermanLanguagePage
        subheading="German Language Courses"
        heading="Learn German with us Today"
        imageSrc={LearnGerman}
        showDecoratorBlob={true}
        textOnLeft={false}
      />
      <FaqPage
        subheading="FAQS"
        heading="Do you have Questions?"
        imageSrc={FAQIMG}
        faqs={homepageFaqs}
      />
      <FooterPage />
    </AnimationRevealPage>
  );
};

export default HomePage;
