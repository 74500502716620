import { HighlightedText, MainHeading } from "components/misc/Headings";

import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section` md:pl-5 relative flex-1`;
const Pill = tw.span`font-serif md:tracking-widest px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section`mt-5  flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;

const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
const TextItem = tw.p`text-gray-500`;
const Icon = tw.div`hidden md:block mr-2 `;
const ListWrapper = tw.ul`ml-3 md:ml-0 list-disc mt-2 md:list-none text-primary-500`;
const Container = tw.section`md:mt-10 max-w-screen-xl mx-auto`;

const OverviewSection = ({ heading, highlight }) => {
  return (
    <Container>
      <MainHeading>
        {heading}
        <HighlightedText>{highlight} </HighlightedText>
      </MainHeading>
      <TwoColumn>
        <LeftColumn>
          <Pill>University Admissions in Germany</Pill>

          <SectionDescription>
            Germany is home to some of the world’s top-ranked universities,
            offering excellent education and research opportunities. Whether
            you're looking to pursue a Bachelor’s, Master’s, or PhD, or need to
            attend a Studienkolleg to qualify for university entry, Germany
            provides a range of academic options for international students.
          </SectionDescription>
          <Pill>Free Education in Germany</Pill>
          <SectionDescription>
            Free Education in Germany Germany offers tuition-free education at
            most public universities for both domestic and international
            students. This means you can pursue a Bachelor’s or Master’s degree
            without paying tuition fees, though a small administrative fee is
            required each semester. This makes Germany one of the most
            affordable and attractive destinations for higher education in the
            world.
          </SectionDescription>

          <Pill>Proof of Finance for Students</Pill>
          <SectionDescription>
            To study in Germany, international students must provide proof of
            financial resources (Finanzierungsnachweis) to secure a student visa
            and residence permit. This ensures you can cover living expenses
            during your studies.
          </SectionDescription>
          <ListWrapper>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Blocked Account (Sperrkonto)</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                The most common method, where you deposit the required amount
                into a blocked account in Germany. You can withdraw a set amount
                monthly. As of 2024, students need to show €11,904 per year (or
                €992 per month) for living costs such as accommodation, food,
                and health insurance.
                <br />
              </SectionDescription>
            </ListItem>
          </ListWrapper>
        </LeftColumn>
        <RightColumn>
          <ListWrapper>
            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Scholarship</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                Scholarships covering living expenses can be used as proof, with
                clear documentation of the amount.
                <br />
              </SectionDescription>
            </ListItem>

            <ListItem>
              <IconWrapper>
                <Icon>
                  <HiBadgeCheck size={20} />
                </Icon>
                <TextItem>
                  <b>Formal Obligation Letter (Verpflichtungserklärung)</b>:
                </TextItem>
              </IconWrapper>
              <SectionDescription>
                A German resident can guarantee your financial support by
                submitting this document.
                <br />
              </SectionDescription>
            </ListItem>
          </ListWrapper>

          <Pill>What We Offer</Pill>

          <SectionDescription>
            At <b>Weg2De</b>, we make your journey to study in Germany seamless
            and hassle-free. Here’s how we can help:
          </SectionDescription>
          <SectionDescription>
            <b>University & Course Search</b>: We assist you in finding the
            right university and study program based on your academic background
            and career goals.
          </SectionDescription>
          <SectionDescription>
            <b>Application Support</b>: Our team guides you through the entire
            application process, including preparing your documents, writing
            motivation letters, and submitting applications.
          </SectionDescription>

          <SectionDescription>
            <b>Payment of Application & School Fees</b>: We assist in managing
            the payment of university application fees and school tuition,
            ensuring all payments are processed correctly and on time.
          </SectionDescription>

          <SectionDescription>
            <b>Visa Support </b>: At Weg2De, we provide tailored health
            insurance options to meet the needs of our clients in various
            situations. Whether you’re a student, worker, or here for a specific
            program, we’ve got you covered!
          </SectionDescription>
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default OverviewSection;
