import AnimationRevealPage from "helpers/AnimationRevealPage";
import React, {useEffect} from "react";
import FooterPage from "components/Footer";
import GermanLanguagePage from "pages/home/GermanLanguage";
import LangIMG from "images/nurse.svg";
import OverviewSection from "./OverviewSection";
import SEO from "components/seo/SEO";

// import RegistrationForm from "../online-group/RegistrationForm";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw.section` mt-32  relative`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;


function GermanHealthInsurancePage() {

  useEffect(() => {
    document.title = "German Health Insurance | German Student Insurance | German Work Insurance | Opportunity Card Insurance - Weg2De";
  }, []);
  return (
    <AnimationRevealPage>
        <SEO
        title="German Health Insurance - Weg2De"
        description="Weg2De - Dr. Walter Insurance, Barmer Student Insurance, Insurance for Opportunity Card"
        name="German Health Insurance - Weg2De "
        type=""
      />
      <Container>
          <OverviewSection heading="German Health" highlight=' Insurance' />
        <GermanLanguagePage
          subheading="German Insurance"
          heading="Need an Insurance?"
          description="Need help finding the right health insurance for your situation in Germany? 
          Whether you're a student, worker, or participating in a special program, we’re here to assist you.
          Reach out to us today and let us guide you through selecting the best insurance plan to meet your needs. 
          Your health and peace of mind are just a message away!"
          imageSrc={LangIMG}
          showDecoratorBlob={true}
          textOnLeft={false}
          primaryButtonText="Contact us"
          primaryButtonUrl="/contact-us"
        /> 
          <FooterPage />
          <DecoratorBlob1 />
          <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
        
  );
}

export default GermanHealthInsurancePage;
