import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import AcademyPage from "pages/academy/Academy.jsx";
import ApprenticeshipProgramPage from "pages/apprenticeship-program/ApprenticeshipProgramPage.jsx";
import AusbildungInGermanyPage from "pages/ausbildung/AusbildungInGermany.jsx";
import BeschleunigtesFVPage from "pages/beschleunigte-FV/BeschleunigteFVPage.jsx";
import CandidatesPage from "pages/candidates/CandidatePage.jsx";
import ChancenkartePage from "pages/chancenkarte/ChancenkartePage.jsx";
import CompaniesPage from "pages/companies/CompaniesPage.jsx";
import ContactUsPage from "pages/contact-us/ContactUs.jsx";
import DisclaimerPage from "pages/others/Disclaimer.jsx";
import GeneralFaqsPage from "pages/general-faqs/GeneralFaqs.jsx";
import GermanHealthInsurancePage from "pages/german-health-insurances/GermanHealthInsurance.jsx";
// import  from "pages/career/GermanHealthInsurance.jsx";
import GlobalStyles from "styles/GlobalStyles";
import Header from "./components/headers/light.js";
import { HelmetProvider } from 'react-helmet-async';
import IndividualCoursePage from "pages/courses/individual/IndividualCourse.jsx";
import ImpressumPage from "pages/others/Impressum.jsx"
import JobSearchPage from "pages/job-search-workshop/JobSearch.jsx";
import KoOperationPage from "pages/ko-operation/KoOperationPage.jsx";
import Landing from "./pages/home/HomePage.jsx";
import OnlineGroupCourse from "pages/courses/online-group/OnlineGroupCourse.jsx";
import OurTeamPage from "pages/our-team";
import PrivacyStatementPage from "pages/others/PrivacyStatement.jsx";
import ProfessionalCoursePage from "pages/courses/professional/ProfessionalCourse.jsx";
import React from "react";
import TermsAndConditionsPage from "pages/others/TermsAndConditions.jsx";
import UniversityAdmission from "pages/university-admissions/UniversityAdmission.jsx";
import VolunteeringPage from "pages/volunteering-in-germany/Volunteering.jsx";
import WorkInGermany from "pages/work-and-live-in-germany/WorkInGermany.jsx";
import ThankYouPage from "pages/ThankYou/ThankYou.jsx";
import Error404 from "pages/error/error404.jsx";

export default function App() {
  return (
    <HelmetProvider>
      <GlobalStyles />
      <Router>
        <section
          style={{
            paddingTop: "30px",
            paddingBottom: "30px",
            position: "fixed",
            top: 0,
            background: "white",
            width: "100%",
            zIndex: "99999",
            boxShadow: "0 20px 50px rgba(0,0,0, 0.075)",
          }}
        >
          <Header roundedHeaderButton />
        </section>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/for-companies" element={<CompaniesPage />} />
          <Route path="/for-individuals" element={<CandidatesPage />} />
          <Route path="/contact-us" element={<ContactUsPage />} />
          <Route path="/akademie" element={<AcademyPage />} />
          <Route path="/our-team" element={<OurTeamPage />} />
          <Route path="/german-online-group-course" element={<OnlineGroupCourse />} />
          <Route path="/german-for-professionals" element={<ProfessionalCoursePage />} />
          <Route path="/individual-courses" element={<IndividualCoursePage />} />
          <Route path="/job-search-workshop" element={<JobSearchPage />} />
          <Route path="/work-and-live-in-germany" element={<WorkInGermany />} />
          <Route path="/apprenticeship-program" element={<ApprenticeshipProgramPage />} />
          <Route path="/impressum" element={<ImpressumPage />} />
          <Route path="/disclaimer" element={<DisclaimerPage />} />
          <Route path="/privacy-statement" element={<PrivacyStatementPage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
          <Route path="/university-admission" element={<UniversityAdmission />} />
          <Route path="/volunteering-in-germany" element={<VolunteeringPage />} />
          <Route path="/german-health-insurances" element={<GermanHealthInsurancePage />} />
          <Route path="/ausbildung-in-germany" element={<AusbildungInGermanyPage />} />
          <Route path="/partner-with-us" element={<KoOperationPage />} />
          <Route path="/Beschleunigtes-fachkräfte-verfahren" element={<BeschleunigtesFVPage />} />
          <Route path="/chancenkarte" element={<ChancenkartePage />} />
          <Route path="/faqs" element={<GeneralFaqsPage />} />
          <Route path="/thank-you" element={<ThankYouPage />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </Router>
    </HelmetProvider>
  );
}
