import React from "react";
import './CloudImages.css'; // Import the CSS file for styling

const CloudImagee = ({ src, alt }) => {
  return (
    <div className="cloud-containers">
      <img src={src} alt={alt} className="cloud-images" />
    </div>
  );
};

export default CloudImagee;
