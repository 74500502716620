import { HighlightedText, MainHeading } from "components/misc/Headings";
//import { HiBadgeCheck } from "react-icons/hi";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import tw from "twin.macro";
import HompageImg from "images/homep.jpg"

const LeftColumn = tw.section` md:pr-5 relative flex-1`;
const RightColumn = tw.section` md:pl-5 relative flex-1`;
//const Pill = tw.span`font-serif md:tracking-widest px-5 mt-16 py-1 text-sm md:text-lg rounded-full text-white text-left bg-primary-500 inline w-5/6 md:w-1/3`;
//const IconWrapper = tw.div`flex items-center text-primary-500`;
const TwoColumn = tw.section`mt-10  flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto`;

//const ListItem = tw.li`text-primary-500 text-left  text-sm md:text-base lg:text-lg font-light mb-3`;
//const TextItem = tw.p`text-gray-500`;
//const Icon = tw.div`hidden md:block mr-2 `;
//const ListWrapper = tw.ul`ml-3 md:ml-0 list-disc mt-2 md:list-none text-primary-500`;
const Container = tw.section`md:mt-10 max-w-screen-xl mx-auto`;

const Image = tw.img` cursor-pointer bg-gray-100  w-80 h-80 rounded-full object-cover`;
const OverviewSection = ({ 
  heading, 
  highlight }) => {
  return (
    <Container>
      <MainHeading>
        {heading}
        <HighlightedText>{highlight} </HighlightedText>
      </MainHeading>
      <TwoColumn>
        <LeftColumn>
        <center><Image src={HompageImg} /></center>
          <SectionDescription>
            Germany is home to some of the world’s top-ranked universities,
            offering excellent education and research opportunities. Whether
            you're looking to pursue a Bachelor’s, Master’s, or PhD, or need to
            attend a Studienkolleg to qualify for university entry, Germany
            provides a range of academic options for international students.
          </SectionDescription>
        
        </LeftColumn>
        <RightColumn>
        <SectionDescription>
            <li><b>Affordable Tuition</b> </li> Most public universities have little to no tuition fees, making quality education accessible to international students.
            world.
          </SectionDescription>

          <SectionDescription>
            <li><b>Work While Studying</b> </li> International students can work up to 120 full days or 240 half days per year, providing valuable work experience and financial support.
          </SectionDescription>
          <SectionDescription>
            <li><b>High Quality of Life</b> </li> Germany offers a safe and welcoming environment with excellent infrastructure, healthcare, and public transport systems.
          </SectionDescription>
          <SectionDescription>
            <li><b>Post-study work opportunities</b> </li>  After graduation, students can apply for an 18-month residence permit to seek employment in Germany.
          </SectionDescription>
          <SectionDescription>
            <li><b>Scholarship Opportunities</b> </li>  Various scholarships, such as DAAD, are available to help cover living costs.
          </SectionDescription>
          
        
        </RightColumn>
      </TwoColumn>
    </Container>
  );
};

export default OverviewSection;
