import { Container, ContentWithPaddingXl } from "components/misc/Layouts.js";
import { HighlightedText, MainHeading } from "components/misc/Headings.js";
import { ReactComponent as LinkedInIcon } from "images/linkedin.svg";
import { ReactComponent as MailIcon } from "images/email.svg";
import React, {useEffect} from "react";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";
import styled from "styled-components";
import tw from "twin.macro";
//import SEO from "components/seo/SEO";

const ContentWrapper = tw.section`flex-wrap flex flex-col lg:flex-row items-center lg:items-stretch`;
const ContentContainer = tw.div` mt-10 lg:w-1/3`;
const Content = tw.div` px-2 text-center max-w-xs mx-auto flex flex-col items-center`;
const Image = tw.img` cursor-pointer bg-gray-100  w-72 h-72 rounded-full object-cover`;
const Role = tw.p`font-serif mr-1 text-primary-500 font-light uppercase tracking-widest`;
const Bio = tw(SectionDescription)`border border-gray-100 rounded md:mx-10 p-3 shadow-special`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-primary-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-primary-500`}
`;
const Section = tw.section`justify-center md:px-10 rounded cursor-pointer transform  flex items-center    w-full md:w-4/5 mx-auto text-primary-500 font-light transform  text-sm leading-relaxed`;
const Name = tw.p`mt-2 font-serif text-secondary-500 font-thin text-base uppercase tracking-widest`;
const SocialLinksContainer = tw.div`flex`;

const SocialLink = styled.a`
  ${tw`border border-gray-200 cursor-pointer p-1 rounded-full bg-gray-100 text-gray-900 hover:bg-white transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-5 h-5`}
  }
`;

function OurTeam({ team }) {

  useEffect(() => {
    document.title = "Our Team - Weg2De";
  }, []);
  return (
    <Container>
      <ContentWithPaddingXl>
        <MainHeading>
          Our <HighlightedText>Team</HighlightedText>
        </MainHeading>
        <ContentWrapper>
          {team?.map((teamInfo) => (
            <ContentContainer key={teamInfo.id}>
              <Content>
                <Image src={teamInfo?.imageSrc} />
                <Name>{teamInfo.name}</Name>
              </Content>
              <Section>
                <Role>{teamInfo?.role}</Role>
                <SocialLinksContainer>
                  <SocialLink href={teamInfo?.linkedInUrl} target="_blank">
                    <LinkedInIcon />
                  </SocialLink>
                  <SocialLink href={teamInfo?.mail} target="_blank">
                    <MailIcon />
                  </SocialLink>
                </SocialLinksContainer>
              </Section>
              <Bio>{teamInfo?.bio}</Bio>
            </ContentContainer>
          ))}
        </ContentWrapper>
      </ContentWithPaddingXl>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
}
export default OurTeam;
