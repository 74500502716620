import React, {useEffect} from "react";
import { Link } from "react-router-dom";

const Error404 = () => {

  useEffect(() => {
    document.title = "Error 404";
  }, []);
  return (


    <div style={styles.container}>
        <br/><br/><br/><br/><br/><br/><br/>
      <h1 style={styles.title}>404</h1>
      <p style={styles.message}>Oops! The page you're looking for doesn't exist.</p>
      <Link to="/" style={styles.link}>
        Go back to Home
      </Link>
    </div>
    
  );
  
  
};

const styles = {
  container: {
    textAlign: "center",
    padding: "50px",
    fontFamily: "Arial, sans-serif",
    color: "#333",
  },
  title: {
    fontSize: "5rem",
    margin: "20px 0",
  },
  message: {
    fontSize: "1.5rem",
    marginBottom: "30px",
  },
  link: {
    fontSize: "1.2rem",
    color: "#007BFF",
    textDecoration: "none",
    border: "1px solid #007BFF",
    padding: "10px 20px",
    borderRadius: "5px",
    transition: "all 0.3s ease",
  },
  linkHover: {
    backgroundColor: "#007BFF",
    color: "#fff",
  },
};

export default Error404;
