import AnimationRevealPage from "helpers/AnimationRevealPage";
import React, {useEffect} from "react";
import CourseObjectivePage from "./CourseObjectivePage";
import FooterPage from "components/Footer";
import GermanLanguagePage from "pages/home/GermanLanguage";
import KeyTopicsPage from "./KeyTopics";
import LangIMG from "images/work.svg";
import OverviewSection from "./OverviewSection";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";
import styled from "styled-components";
import tw from "twin.macro";

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 top-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;
const Container = tw.section` mt-32  relative`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;


function WorkInGermany() {

    useEffect(() => {
      document.title = "Working and Living in Germany Workshop | Work in Germany - Weg2De Akademie";
    }, []);

  return (
    <AnimationRevealPage>
      <Container>
        <OverviewSection heading="Work in " highlight=' Germany' />
        <CourseObjectivePage />
        <KeyTopicsPage />
        <GermanLanguagePage
          subheading="Work in Germany"
          heading="Get Started Today!"
          description="Are you ready to start your new chapter in Germany with confidence and clarity? 
          Let us help you navigate the complexities of working and living in Germany. Contact us today to learn more about our 
          coaching services and schedule your first session. We look forward to supporting you on your journey to success in Germany!"
          primaryButtonText="Contact us"
          primaryButtonUrl="/contact-us"
          imageSrc={LangIMG}
          showDecoratorBlob={true}
          textOnLeft={false}
        />
        <FooterPage />
        <DecoratorBlob1 />
        <DecoratorBlob />
      </Container>
    </AnimationRevealPage>
  );
}

export default WorkInGermany
