import { HighlightedText, MainHeading } from "components/misc/Headings";

import HompageImg from "images/Study In Germany.png";
import { Link } from "react-router-dom";
import React from "react";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.section`mt-16 relative`;
const TwoColumn = tw.section`flex flex-col lg:flex-row lg:items-center max-w-screen-xl mx-auto py-5 md:py-10`;
const LeftColumn = tw.section`relative md:-mr-20 lg:w-5/12 text-center max-w-lg mx-auto lg:max-w-none lg:text-left`;

const RightColumn = tw.section`relative mt-12 lg:mt-0 flex-1 flex flex-col justify-center lg:self-center`;
const Paragraph = tw.p`my-5 font-serif lg:my-8 text-lg xl:text-xl text-gray-500`;
const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

const ButtonsContainer = styled.div`
  ${tw`flex lg:justify-start justify-around md:items-center my-10 flex-wrap`}
`;

const FirstButton = styled(Link)`
  ${tw`font-serif px-10 lg:tracking-wide sm:tracking-tighter text-sm uppercase md:px-10  bg-secondary-500 text-white font-normal my-4 sm:my-2 rounded-full py-2 md:py-6 text-center sm:leading-none focus:outline-none hover:bg-white hover:border hover:border-secondary-500 hover:text-gray-500 transition duration-300`}
`;

const SecondButton = styled(Link)`
  ${tw`font-serif px-10 lg:tracking-wide sm:tracking-tighter text-sm uppercase md:px-10  bg-white text-gray-500 border border-gray-500 font-normal md:mr-2 my-4 sm:my-2 rounded-full py-2 md:py-6 text-center  sm:leading-none focus:outline-secondary-500 hover:bg-secondary-500 hover:border-0 hover:text-white transition duration-300`}
`;


function LandingPage() {
  return (
    <>
      <Container>
        <TwoColumn>
          <RightColumn>
            <Paragraph>
            Top Universities, Low Tuition, and endless Opportunities awaits you!
            </Paragraph>
            <MainHeading>
            {" "}
            Your <HighlightedText>Study</HighlightedText> {" "}in 
              <HighlightedText> Germany </HighlightedText> Journey Starts Here!
            </MainHeading>
            <ButtonsContainer>
              <SecondButton to="https://termin.weg2de.com/#/infosession">
                Register for our next free seminar
              </SecondButton>
              <FirstButton to="https://chat.whatsapp.com/CiQFwIlVQ87GQlR2xateBN">
                Join our WhatsApp Community
              </FirstButton>
            </ButtonsContainer>
          </RightColumn>
          <LeftColumn>
          <IllustrationContainer>
              <img
                tw="min-w-0 w-full max-w-lg xl:max-w-3xl h-full"
                src={HompageImg}
                alt="lady  holding a passport and travelling"
              />
            </IllustrationContainer> 
          </LeftColumn>
        </TwoColumn>
        <DecoratorBlob1 />
      </Container>
    </>
  );
}

export default LandingPage;
