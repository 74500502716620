import {
  HighlightedText,
  MainHeading,
  //SectionHeading,
  //Subheading,
} from "components/misc/Headings";

import AnimationRevealPage from "helpers/AnimationRevealPage";
import FooterPage from "components/Footer";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
import SEO from "components/seo/SEO"
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.section`mt-32 relative max-w-screen-xl mx-auto`;
//const HorizontalLine = tw.hr`border-gray-100 mb-5`;
const Wrapper = tw.section`mt-10`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

function DisclaimerPage() {
  return (
    <AnimationRevealPage>
            <SEO
        title="Disclaimer - Weg2De"
        description="Disclaimer - Weg2De - German Online Group Course | Learn German Online | German Individual Courses | German for Professionals "
        name="Weg2De"
        type=""
      />
      <Container>
        <MainHeading><HighlightedText>Disclaimer</HighlightedText></MainHeading>
        <Wrapper>
          <SectionDescription>
            Weg2De is committed to keeping this
            website up to date and accurate. Should you nevertheless encounter
            anything that is incorrect or out of date, we would appreciate it if
            you could let us know. Please indicate where on the website you read
            the information. We will then look at this as soon as possible.
            Please send your response by email to: info@weg2de.com.
          </SectionDescription>

          <SectionDescription>
            We are not liable for loss as a result of inaccuracies or
            incompleteness, nor for loss resulting from problems caused by or
            inherent to the dissemination of information through the internet,
            such as disruptions or interruptions. When using web forms, we
            strive to limit the number of required fields to a minimum. For any
            loss suffered as a result of the use of data, advice or ideas
            provided by or on behalf of Weg2De via
            this website, Weg2De accepts no liability.
          </SectionDescription>

          <SectionDescription>
            Responses and privacy inquiries submitted by email or using a web
            form will be treated in the same way as letters. This means that you
            can expect a response from us within a period of 1 month at the
            latest. In the case of complex requests, we will let you know within
            1 month if we need a maximum of 3 months.
          </SectionDescription>

          <SectionDescription>
            Any personal data you provide us with in the context of your
            response or request for information will only be used in accordance
            with our privacy statement.
          </SectionDescription>

          <SectionDescription>
            Weg2De shall make every reasonable effort
            to protect its systems against any form of unlawful use. Weg2De
           shall implement appropriate technical and
            organizational measures to this end, taking into account, among
            other things, the state of the art. However, it shall not be liable
            for any loss whatsoever, direct and/or indirect, suffered by a user
            of the website, which arises as a result of the unlawful use of its
            systems by a third party.
          </SectionDescription>

          <SectionDescription>
            Weg2De accepts no responsibility for the
            content of websites to which or from which a hyperlink or other
            reference is made. Products or services offered by third parties
            shall be subject to the applicable terms and conditions of those
            third parties.
          </SectionDescription>

          <SectionDescription>
            All intellectual property rights to content on this website are
            vested in Weg2De or in third parties who
            have placed the content themselves or from whom Weg2De has obtained a user license.
          </SectionDescription>

          <SectionDescription>
            Copying, disseminating and any other use of these materials is not
            permitted without the written permission of Weg2De, except and only insofar as otherwise stipulated in
            regulations of mandatory law (such as the right to quote), unless
            specific content dictates otherwise.
          </SectionDescription>

          <SectionDescription>
            If you have any questions or problems with the accessibility of the
            website, please do not hesitate to contact us.
          </SectionDescription>
        </Wrapper>
        <DecoratorBlob1 />
      </Container>
      <FooterPage />
    </AnimationRevealPage>
  );
}

export default DisclaimerPage;
