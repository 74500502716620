import {
  HighlightedText,
  MainHeading,
  Subheading,
} from "components/misc/Headings";

import AnimationRevealPage from "helpers/AnimationRevealPage";
import FooterPage from "components/Footer";
import { Link } from "react-router-dom";
import React from "react";
import { SectionDescription } from "components/misc/Typography";
import SEO from "components/seo/SEO"
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-1.svg";
//eslint-disable-next-line
import styled from "styled-components";
import tw from "twin.macro";

const Container = tw.section`mt-32 relative max-w-screen-xl mx-auto pb-10`;
const Wrapper = tw.section`mt-10`;
const InLink = tw(Link)`underline text-primary-500`;
//const Download = tw.a`text-primary-500 underline`;
const ListWrapper = tw.ul`ml-3 md:ml-5 list-disc marker:text-primary-500 text-gray-500 font-light text-sm md:text-lg`;
const ListWrapperDecimal = tw.ul`ml-3 md:ml-5 list-decimal marker:text-primary-500 text-gray-500 font-light text-sm md:text-lg`;
const ListItem = tw.li`mb-2 last:mb-5`;
const Title = tw(Subheading)`text-lg`;

// Random Decorator Blobs (shapes that you see in background)
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`;

function TermsAndConditionsPage() {
  return (
    <AnimationRevealPage>
      <SEO
        title="Terms & Conditions - Weg2De"
        description="Terms & Conditions - Weg2De - German Online Group Course | Learn German Online | German Individual Courses | German for Professionals "
        name="Weg2De"
        type=""
      />
      <Container>
        <MainHeading>
          Terms<HighlightedText> and </HighlightedText>Conditions
        </MainHeading>
        <Wrapper>
          <SectionDescription>
            The Terms and Conditions were last updated on <HighlightedText>January 14, 2025</HighlightedText>
          </SectionDescription>
          <Title>1. Introduction</Title>
          <SectionDescription>
            These Terms and conditions apply to this website and to the
            transactions related to our products and services. You may be bound
            by additional contracts related to your relationship with us or any
            products or services that you receive from us. If any provisions of
            the additional contracts conflict with any provisions of these
            Terms, the provisions of these additional contracts will control and
            prevail.
          </SectionDescription>

          <Title>2. Binding</Title>
          <SectionDescription>
            By registering with, accessing, or otherwise using this website, you
            hereby agree to be bound by these Terms and conditions set forth
            below. The mere use of this website implies the knowledge and
            acceptance of these Terms and conditions. In some particular cases,
            we can also ask you to explicitly agree.
          </SectionDescription>

          <Title>3. Electronic communication</Title>
          <SectionDescription>
            By using this website or communicating with us by electronic means,
            you agree and acknowledge that we may communicate with you
            electronically on our website or by sending an email to you, and you
            agree that all agreements, notices, disclosures, and other
            communications that we provide to you electronically satisfy any
            legal requirement, including but not limited to the requirement that
            such communications should be in writing.
          </SectionDescription>

          <Title>4. Intellectual property</Title>
          <SectionDescription>
            We or our licensors own and control all of the copyright and other
            intellectual property rights in the website and the data,
            information, and other resources displayed by or accessible within
            the website.
          </SectionDescription>
          <SectionDescription>
            <Subheading>4.1 All the rights are reserved</Subheading>
            Unless specific content dictates otherwise, you are not granted a
            license or any other right under Copyright, Trademark, Patent, or
            other Intellectual Property Rights. This means that you will not
            use, copy, reproduce, perform, display, distribute, embed into any
            electronic medium, alter, reverse engineer, decompile, transfer,
            download, transmit, monetize, sell, market, or commercialize any
            resources on this website in any form, without our prior written
            permission, except and only insofar as otherwise stipulated in
            regulations of mandatory law (such as the right to quote).
          </SectionDescription>

          <Title>5. Newsletter</Title>
          <SectionDescription>
            Notwithstanding the foregoing, you may forward our newsletter in the
            electronic form to others who may be interested in visiting our
            website.
          </SectionDescription>

          <Title>6. Third-party property</Title>
          <SectionDescription>
            Our website may include hyperlinks or other references to other
            party’s websites. We do not monitor or review the content of other
            party’s websites which are linked to from this website. Products or
            services offered by other websites shall be subject to the
            applicable Terms and Conditions of those third parties. Opinions
            expressed or material appearing on those websites are not
            necessarily shared or endorsed by us.
          </SectionDescription>
          <SectionDescription>
            We will not be responsible for any privacy practices or content of
            these sites. You bear all risks associated with the use of these
            websites and any related third-party services. We will not accept
            any responsibility for any loss or damage in whatever manner,
            however caused, resulting from your disclosure to third parties of
            personal information.
          </SectionDescription>

          <Title>7. Responsible use</Title>
          <SectionDescription>
            By visiting our website, you agree to use it only for the purposes
            intended and as permitted by these Terms, any additional contracts
            with us, and applicable laws, regulations, and generally accepted
            online practices and industry guidelines. You must not use our
            website or services to use, publish or distribute any material which
            consists of (or is linked to) malicious computer software; use data
            collected from our website for any direct marketing activity, or
            conduct any systematic or automated data collection activities on or
            in relation to our website.
          </SectionDescription>
          <SectionDescription>
            Engaging in any activity that causes, or may cause, damage to the
            website or that interferes with the performance, availability, or
            accessibility of the website is strictly prohibited.
          </SectionDescription>

          <Title>
            8. COURSE TRANSFER AND CANCELLATION – German Language Courses
          </Title>
          <Subheading>8.1 Transfer by Weg2De</Subheading>
          <SectionDescription>
            Weg2De Akademie may transfer students to the next available course
            if no courses are slated to begin on the start date booked, students
            are assigned to the nearest course start date available.
          </SectionDescription>
          <Subheading>8.2 Cancellation by Student:</Subheading>
          <SectionDescription>
            If a student wishes to cancel their course, they must notify Weg2De
            Akademie in writing by sending an email to akademie@weg2de.com.
            Cancellations by phone will not be accepted. The following terms
            apply:
          </SectionDescription>
          <ListWrapperDecimal>
            <ListItem>
              {" "}
              <b>Cancellations made up to 4 weeks</b> prior to the
              start of the course; 10% of the course fee will be deducted <br/> 
              <b>Cancellations made up to 1 week</b> prior to the
              start of the course; 20% of the course fee will be deducted<br/> 
              <b>Cancellations made up to 1 day</b> prior to the
              start of the course; 30% of the course fee will be deducted<br/> 
              <b>Cancellations made after course starts</b>; No refund.<br/>
              <b>For one-one lectures: Appointment Cancellations</b>; 75% of daily fee.
              
            </ListItem>
            <ListItem>
            Alternatively, you can postpone your booking to a later start date with no administration fee deductions applied if you rebook
            two weeks before course starts. 
            </ListItem>
            <ListItem>No refunds are available for non-attendance.</ListItem>
            <ListItem> Credit card fees are non-refundable.</ListItem>
            <ListItem>
              Due to the nature of the courses, we cannot, in any event,
              pro-rata classes or transfer unused class time to another course.
            </ListItem>
            <ListItem>
              Cancellations, in full or in parts, are non-refundable after the
              course starts, irrespective of whether the student has attended
              any class or not. If a student abandons the course for reasons
              unconnected with any fault on the part of Weg2De Akademie there
              will be no refund. The course fee is in no case transferable to
              another person
            </ListItem>
          </ListWrapperDecimal>
          <Title>9 REFUND POLICY:</Title>
          <SectionDescription>
            There will be no refunds in case of delayed arrival at a course or
            for non-attendance of classes. Such an absence cannot be compensated
            for by an extension of the course period.
          </SectionDescription>
          <ListWrapper>
            <ListItem>
              Attendance is compulsory. More than 20% of absences without prior
              notice will not be tolerated and may result in the student being
              removed from the course without a refund.
            </ListItem>
            <ListItem>
              Refunds will be processed within 10 – 14 working days of
              notification of the discount or refund.
            </ListItem>
            <ListItem>
              Where the Classes has been offered or is in progress, refund
              application in this instance will not be granted.
            </ListItem>
            <ListItem>
              In the event Weg2De Akademie is unable to run a course or make
              suitable alternative arrangements under the Minimum Number of
              Students policy, the course may either be discounted, or the
              Client will be entitled to a full refund.
            </ListItem>
            <ListItem>
              Refund requests will be processed only if they meet the conditions
              outlined in the cancellation policy.
            </ListItem>
            <ListItem>
              Refunds will be made using the same method as the original
              payment.
            </ListItem>
          </ListWrapper>
          <Title>10. Idea submission</Title>
          <SectionDescription>
            Do not submit any ideas, inventions, works of authorship, or other
            information that can be considered your own intellectual property
            that you would like to present to us unless we have first signed an
            agreement regarding the intellectual property or a non-disclosure
            agreement. If you disclose it to us absent such written agreement,
            you grant to us a worldwide, irrevocable, non-exclusive,
            royalty-free license to use, reproduce, store, adapt, publish,
            translate and distribute your content in any existing or future
            media.
          </SectionDescription>

          <Title>11. Termination of use</Title>
          <SectionDescription>
            We may, in our sole discretion, at any time modify or discontinue
            access to, temporarily or permanently, the website or any Service
            thereon. You agree that we will not be liable to you or any third
            party for any such modification, suspension or discontinuance of
            your access to, or use of, the website or any content that you may
            have shared on the website. You will not be entitled to any
            compensation or other payment, even if certain features, settings,
            and/or any Content you have contributed or have come to rely on, are
            permanently lost. You must not circumvent or bypass, or attempt to
            circumvent or bypass, any access restriction measures on our
            website.
          </SectionDescription>

          <Title>12. Warranties and liability</Title>
          <SectionDescription>
            Nothing in this section will limit or exclude any warranty implied
            by law that it would be unlawful to limit or to exclude. This
            website and all content on the website are provided on an “as is”
            and “as available” basis and may include inaccuracies or
            typographical errors. We expressly disclaim all warranties of any
            kind, whether express or implied, as to the availability, accuracy,
            or completeness of the Content. We make no warranty that:
          </SectionDescription>
          <ListWrapper>
            <ListItem>
              this website or our products or services will meet your
              requirements;
            </ListItem>
            <ListItem>
              this website will be available on an uninterrupted, timely,
              secure, or error-free basis;
            </ListItem>
            <ListItem>
              the quality of any product or service purchased or obtained by you
              through this website will meet your expectations.
            </ListItem>
          </ListWrapper>
          <SectionDescription>
            Nothing on this website constitutes or is meant to constitute,
            legal, financial or medical advice of any kind. If you require
            advice you should consult an appropriate professional.
          </SectionDescription>
          <SectionDescription>
            The following provisions of this section will apply to the maximum
            extent permitted by applicable law and will not limit or exclude our
            liability in respect of any matter which it would be unlawful or
            illegal for us to limit or to exclude our liability. In no event
            will we be liable for any direct or indirect damages (including any
            damages for loss of profits or revenue, loss or corruption of data,
            software or database, or loss of or harm to property or data)
            incurred by you or any third party, arising from your access to, or
            use of, our website.
          </SectionDescription>
          <SectionDescription>
            Except to the extent any additional contract expressly states
            otherwise, our maximum liability to you for all damages arising out
            of or related to the website or any products and services marketed
            or sold through the website, regardless of the form of legal action
            that imposes liability (whether in contract, equity, negligence,
            intended conduct, tort or otherwise) will be limited to the total
            price that you paid to us to purchase such products or services or
            use the website. Such limit will apply in the aggregate to all of
            your claims, actions and causes of action of every kind and nature.
          </SectionDescription>

          <Title>13. Privacy</Title>
          <SectionDescription>
            To access our website and/or services, you may be required to
            provide certain information about yourself as part of the
            registration process. You agree that any information you provide
            will always be accurate, correct, and up to date.
          </SectionDescription>
          <SectionDescription>
            We take your personal data seriously and are committed to protecting
            your privacy. We will not use your email address for unsolicited
            mail. Any emails sent by us to you will only be in connection with
            the provision of agreed products or services.
          </SectionDescription>

          <SectionDescription>
            We have developed a policy to address any privacy concerns you may
            have. For more information, please see our{" "}
            <InLink to="/privacy-statement">Privacy Statement</InLink> and our{" "}
            <InLink to="/cookie-policy">Cookie Policy</InLink>.
          </SectionDescription>

          <Title>14. Export restrictions / Legal compliance</Title>
          <SectionDescription>
            Access to the website from territories or countries where the
            Content or purchase of the products or Services sold on the website
            is illegal is prohibited. You may not use this website in violation
            of export laws and regulations of Germany.
          </SectionDescription>

          <Title>15. Assignment</Title>
          <SectionDescription>
            You may not assign, transfer or sub-contract any of your rights
            and/or obligations under these Terms and conditions, in whole or in
            part, to any third party without our prior written consent. Any
            purported assignment in violation of this Section will be null and
            void.
          </SectionDescription>

          <Title>16. Breaches of these Terms and conditions</Title>
          <SectionDescription>
            Without prejudice to our other rights under these Terms and
            Conditions, if you breach these Terms and Conditions in any way, we
            may take such action as we deem appropriate to deal with the breach,
            including temporarily or permanently suspending your access to the
            website, contacting your internet service provider to request that
            they block your access to the website, and/or commence legal action
            against you.
          </SectionDescription>

          <Title>17. Force majeure</Title>
          <SectionDescription>
            Except for obligations to pay money hereunder, no delay, failure or
            omission by either party to carry out or observe any of its
            obligations hereunder will be deemed to be a breach of these Terms
            and conditions if and for as long as such delay, failure or omission
            arises from any cause beyond the reasonable control of that party.
          </SectionDescription>

          <Title>18. Indemnification</Title>
          <SectionDescription>
            You agree to indemnify, defend and hold us harmless, from and
            against any and all claims, liabilities, damages, losses and
            expenses, relating to your violation of these Terms and conditions,
            and applicable laws, including intellectual property rights and
            privacy rights. You will promptly reimburse us for our damages,
            losses, costs and expenses relating to or arising out of such
            claims.
          </SectionDescription>

          <Title>19. Waiver</Title>
          <SectionDescription>
            Failure to enforce any of the provisions set out in these Terms and
            Conditions and any Agreement, or failure to exercise any option to
            terminate, shall not be construed as waiver of such provisions and
            shall not affect the validity of these Terms and Conditions or of
            any Agreement or any part thereof, or the right thereafter to
            enforce each and every provision.
          </SectionDescription>

          <Title>20. Language</Title>
          <SectionDescription>
            These Terms and Conditions, together with our{" "}
            <InLink to="/privacy-statement">privacy statement</InLink> and{" "}
            <InLink to="/cookie-policy">cookie policy</InLink>, constitute the
            entire agreement between you and Weg2De in
            relation to your use of this website.
          </SectionDescription>

          <Title>21. Updating of these Terms and conditions</Title>
          <SectionDescription>
            We may update these Terms and Conditions from time to time. The date
            provided at the beginning of these Terms and Conditions is the
            latest revision date. We will give you a written notice of any
            changes or updates, and the revised Terms and Conditions will become
            effective from the date that we give you such a notice. Your
            continued use of this website following the posting of changes or
            updates will be considered notice of your acceptance to abide by and
            be bound by these Terms and Conditions. To request a prior version
            of these Terms and conditions, please contact us.
          </SectionDescription>

          <Title>22. Choice of Law and Jurisdiction</Title>
          <SectionDescription>
            These Terms and Conditions shall be governed by the laws of Germany.
            Any disputes relating to these Terms and Conditions shall be subject
            to the jurisdiction of the courts of Germany. If any part or
            provision of these Terms and Conditions is found by a court or other
            authority to be invalid and/or unenforceable under applicable law,
            such part or provision will be modified, deleted and/or enforced to
            the maximum extent permissible so as to give effect to the intent of
            these Terms and Conditions. The other provisions will not be
            affected.
          </SectionDescription>

          <Title>23. Contact information</Title>
          <SectionDescription>
            This website is owned and operated by Weg2De
          </SectionDescription>
          <SectionDescription>
            You may contact us regarding these Terms and Conditions by writing
            or emailing us at the following address: akademie@weg2de.com
          </SectionDescription>

        </Wrapper>
        <DecoratorBlob1 />
      </Container>
      <FooterPage />
    </AnimationRevealPage>
  );
}

export default TermsAndConditionsPage;
